import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import styles from '../styles'
import BannerImage from './BannerImage'
import Spacer from './Spacer'
import jellygonLogo from '../images/Jellygon Logo White Large.png'

const useStyles = makeStyles(theme => ({
  ...styles(theme),
  jellygonSplash: {
    background: "#051320",
    padding: "50px 25px 50px 25px",
    color: "#fff",
    alignItems: "center",
    marginBottom: "50px",
    [theme.breakpoints.down('sm')]: {
      padding: "25px 25px 25px 25px",
      marginBottom: "0"
    },
  },
  label: {
    textAlign: "center",
    [theme.breakpoints.down('xs')]: {
      fontSize: "24pt"
    },
  },
}))

const GenericBanner = ({label, ...props}) => {
  const classes = useStyles()
  
  return (
    <React.Fragment>
      <BannerImage image={jellygonLogo} imageStyles={{maxWidth: "150px"}} className={classes.jellygonSplash} {...props}>
        <Spacer amount="30px" />
        <Typography variant="h3" className={classes.label}>{label}</Typography>
      </BannerImage>
    </React.Fragment>
  )
}

export default GenericBanner