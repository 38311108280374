import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../styles';
import { Container, Typography } from '@material-ui/core'
import GenericBanner from '../components/GenericBanner'
import Footer from '../components/Footer'
import OutboundLink from '../components/OutboundLink'

const useStyles = makeStyles(theme => { return {
  ...styles(theme)
}})

function AboutPage() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.root}>
        <GenericBanner label="ABOUT US" />
        <Container maxWidth="md" className={classes.lightContainer}>
          <Typography variant="body1">Jellygon, LLC is <OutboundLink href="https://twitter.com/all_iver">Oliver Smith</OutboundLink>, 
          a programmer and independent video game developer in Portland, Oregon.  I 
          made the mobile games <OutboundLink href="https://apps.apple.com/us/app/puncho-fighto/id514207451?ls=1#">Puncho 
          Fighto</OutboundLink> and <OutboundLink href="https://apps.apple.com/us/app/particool/id1126135782">Particool</OutboundLink>, as well as a 
          bunch of smaller games like <OutboundLink href="https://www.youtube.com/watch?v=WMGhh3ySnIg">Shitty 
          Voltron</OutboundLink> (with some friends).  I also made the game development tool <OutboundLink 
          href="https://u3d.as/tix">Shapes2D</OutboundLink>.
          <br /><br />You can get in touch at <a href="mailto:support@jellygon.com">support@jellygon.com</a>.
          <br /><br />
          <h4>Social</h4>
          <OutboundLink href="https://discord.gg/U7x8Yum">Jellygon Discord</OutboundLink><br />
          <OutboundLink href="https://twitter.com/jellygon">Jellygon's Twitter</OutboundLink><br />
          <OutboundLink href="https://twitter.com/all_iver">Oliver's Twitter</OutboundLink><br />
          <OutboundLink href="https://www.instagram.com/parasocialophus/">Oliver's Instagram</OutboundLink><br />
          </Typography>
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default AboutPage